* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 30px;
  background-color: beige;
  height: 100px;
  border-bottom: 4px solid black;
  .navbarItems {
    font-size: large;
    color: black;
    font-weight: bold;
    border-radius: 15px;
    padding: 10px 20px;
    background-color: aqua;
  }
  .navbarItems:hover {
    background-color: rgb(0, 200, 255);
    outline: 1px solid black;
    transition: 0.3s;
  }
}

.pageContent {
  width: 100%;
  margin-top: 50px;
  text-align: center;
}
